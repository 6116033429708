$(function () {
    $('body').click(function () {
        $('.mega-container').hide();
        $('#find-dropdown').removeClass('active');
    });

    $('.mega-container').click(function (e) {
        e.cancelBubble = true;
        e.preventDefault();
        return false;
    });

    $('.mega-container a').click(function (e) {
        var type = ($(this).attr('data-type'));
        var id = ($(this).attr('data-value'));

        if (type == 'others') {
            var href = ($(this).attr('href'));
            $('#find_menu_id').val(href);
        }
        else {
            $('#find_menu_id').val(id);
        }

        $('#find_menu_type').val(type);
        var text = ($(this).text());
        $('.find-dropdown').text(text);
        $('.mega-container').toggle();
        $(this).toggleClass('active');

        return false;
    });

    $('.find-dropdown').click(function (e) {
        e.cancelBubble = true;
        e.preventDefault();
        
        $(this).parents('#carousel-hero').toggleClass('menu-type-opened');
        return false;
    });

    $('#search #cuisine').trigger('change');
    $('#search #category').trigger('change');
});